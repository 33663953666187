import "./App.scss";
import Main from "./pages/main.js";
import Portfolio from "./pages/portfolio.js";
import NavBar from "./components/navbar";
import Fotografie from "./pages/fotografie";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route path="/main" exact component={Main} />
      </Switch>
      <Switch>
        <Route path="/portfolio" component={Portfolio} />
      </Switch>
      <Switch>
        <Route path="/fotografie" component={Fotografie} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

// routing of pages
