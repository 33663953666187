import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class NavBar extends React.Component {
  render() {
    return (
      <Navbar className="custom-nav" bg="light" expand="lg">
        <Navbar.Brand href="#home">Sodity</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto ">
            <NavLink className="NavLink padding-sm" to="/main">
              Home
            </NavLink>

            <NavLink className="NavLink padding-sm" to="/portfolio">
              Portfolio
            </NavLink>
            <NavLink className="NavLink padding-sm" to="/fotografie">
              Fotografie
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
