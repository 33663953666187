import React from "react";
import { Row, Col } from "react-bootstrap";
import Neurohabits from "../components/neurohabits";
import Zakenboxx from "../img/zakenboxx.svg";

class Portfolio extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col className="text-center padding-md col-md-12">
            <h1>Portfolio</h1>
            <p>under construction</p>
          </Col>
        </Row>
      </>
    );
  }
}

export default Portfolio;
