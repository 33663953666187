import React from "react";
import { Row, Col } from "react-bootstrap";

class Fotografie extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col className="padding-md text-center col-md-12" md={6}>
            <h1>Fotografie</h1>
            <iframe
              src="http://sodfashafik.tumblr.com/"
              width="100%"
              height="1000"
              frameborder="0"
            ></iframe>
          </Col>
        </Row>
      </>
    );
  }
}

export default Fotografie;
