import React from "react";
import { Row, Col } from "react-bootstrap";
import waterball from "../img/waterball.gif";

class Main extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col className="padding-md text-center col-md-12" md={6}>
            <h1>Welkom</h1>
            <p>bij Sodity</p>
          </Col>
          <Col className="col-md-12 text-center">
            <img className="waterball" src={waterball} />
          </Col>
        </Row>
      </>
    );
  }
}

export default Main;
